<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Prayer Request Details</h1>
    </page-header>
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <h1 class="sm mb-3">{{ translations.tcPrayerRequestDetails }}</h1>
        <div class="d-flex mb-3">
          <i class="mr-3">
            <iCalendar />
          </i>
          <p>{{ prayerDate }}</p>
        </div>
        <div class="d-flex">
          <span class="label font-style-1 mr-2">{{ translations.tcTitle }}:</span>
          <span class="value font-style-1 font-weight-bold">
            {{ selectedPrayerRequest.details.title }}
            <span class="i-tooltip" v-b-tooltip.hover :title="translations.tcPrayerTitle">
              <i-tooltip />
            </span>
          </span>
        </div>
      </section>
      <section class="section-4 bg-block mb-4" v-if="showImage">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcPicture }}</h2>
        </header>
        <div class="body">
          <img :src="imgUrl" style="width: 250px" class="img-responsive img-thumbnail" />
        </div>
      </section>
      <section class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcDetails }}</h2>
        </header>
        <div class="body">
          <p v-html="selectedPrayerRequest.details.detail"></p>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button variant="primary" @click="handleEditClick" class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">
              {{ translations.tcEdit }}
            </b-button>
            <b-button variant="tertiary" @click="handleBackClick" class="flex-0 w-100-sd mb-sm-0">
              {{ translations.tcBack }}
            </b-button>
          </div>
        </div>
      </section>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iMapPoint from '@/assets/svgs/iMapPoint.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'prayer-details',
  mixins: [translationMixin],
  data() {
    return {
      imgUrl: '',
      translations: {}
    }
  },
  methods: {
    ...mapActions({
      getImageSrcUrl: 'prayerCenter/getImageSrcUrl',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    handleEditClick() {
      this.$router.push({
        name: 'prayer-edit-calendar-item',
      })
    },
    handleBackClick() {
      this.$router.go(-1)
    },
    async pageLoad() {
      if (this.showImage) {
        this.imgUrl = await this.getImageSrcUrl(this.selectedPrayerRequest.details.img_image_path)
      }
    },
  },
  async created() {
    if (!this.selectedPrayerRequest.details) {
      this.$router.push({ name: 'prayer-center-monthly' })
    }
    this.setLoadingStatus(true)
    await Promise.all([
      this.pageLoad(),
      this.getViewTranslations()
    ]).then(() => {
      this.setLoadingStatus(false)
    })
  },
  computed: {
    ...mapGetters({
      selectedPrayerRequest: 'prayerCenter/selectedPrayerRequest',
      prefCulture: 'user/userPreferredCulture'
    }),
    prayerDate() {
      let showingDate = new Date()
      let monthNumberString = showingDate.getMonth() + 1
      let date = new Date(
        `${showingDate.getFullYear()}-${showingDate.getMonth() + 1}-${(this.selectedPrayerRequest.details.day < 10 ? '0' : '')}${this.selectedPrayerRequest.details.day}T03:24:00`
      )
      return date.toDateString()
    },
    showImage() {
      if (!this.selectedPrayerRequest.details) {
        return false
      }
      return !!this.selectedPrayerRequest.details.img_image_path
    },
    canEdit() {
      return !!this.orgKey
    },
  },
  components: {
    iCalendar: iCalendar,
    iMapPoint: iMapPoint,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}
</style>
